import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getTopMenu } from '@/store/selectors';

import logo from '@/assets/logo/logo_dobro.svg';
import MenuItem from '../MenuItem/MenuItem';
import Menu from '../Menu/Menu';

import styles from './NavigationTop.module.scss';

export function NavigationTop() {

	const menu = useSelector(getTopMenu);

	return (
		<div className={styles.container}>
			<div className={styles.logo}>
				<Link to={'/'} title={'На главную'} className={styles.link}>
					<img src={logo} alt={'Логотип'} className={styles.logoImage}/>
				</Link>
			</div>
			<div className={styles.menu}>
				<Menu place={'header'}>
					{menu.map((el, index) => <MenuItem key={index} item={el}/>)}
				</Menu>
			</div>
		</div>
	);
}
