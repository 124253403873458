import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';

import styles from './NewsList.module.scss';
import parseHtml from '@/utils/htmlParserWithCustomLinks';
import { useSelector } from 'react-redux';
import { getCachedNews, getSelectedCityId } from '@/store/selectors';
import { loadNews } from '@/api';
import { useLocation } from 'react-router';
import { Picture } from '@/components/Picture';
import PageNotFound from '@/components/PageNotFound';
import { usePagination } from '@/hooks/usePagination';

function NewsList(props) {

	const { perPage = 6, noPagination = false } = props;

	const currentCityId = useSelector(getSelectedCityId);
	const cachedNews = useSelector(getCachedNews);
	const location = useLocation();
	const [news, setNews] = useState(() => cachedNews ?? { pages: [], total: 0 });
	const [newsLoading, setNewsLoading] = useState(false);

	const page = useMemo(() => {
		const searchParams = new URLSearchParams(location.search);
		return Number(searchParams.get('list') ?? '0');
	}, [location]);

	useEffect(() => {
		setNewsLoading(true);
		loadNews(currentCityId, page, perPage)
			.then(setNews)
			.finally(() => setNewsLoading(false));
	}, [page, currentCityId, perPage]);

	const renderPage = useCallback((pageIndex) => {
		const linkClassName = classNames([styles.pageLink, {
			[styles.active]: pageIndex === page,
		}]);
		return (
			<div key={pageIndex} className={styles.page}>
				<Link className={linkClassName} to={`?list=${pageIndex}`}>{pageIndex + 1}</Link>
			</div>
		);
	}, [page]);

	const pages = usePagination(renderPage, news.total, perPage, page);

	if (page !== 0 && news.length === 0) {
		return <PageNotFound/>;
	}

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				{newsLoading && <Loader/>}
				{news.pages.map((page, index) => {
					const vars = page?.templateVars?.pageVars;
					const link = page.fullPath;
					const shortText = vars.shortText ?? '';
					const date = moment(page.publishedAt);
					const isFirstItem = page === 0 && index === 0;
					const title = page.title;
					const newsBoxClassName = classNames({
						[styles.first]: isFirstItem,
						[styles.newsBox]: true,
					});
					return <Link to={link} className={newsBoxClassName} key={index}>
						<article className={styles.newsContent} itemScope itemType="http://schema.org/NewsArticle">
							<div className={styles.imgBox}>
								<Picture
									className={styles.img}
									image={vars.image?.image}
									desktopType={'list'}
									mobileType={'list'}
									alt={vars.image?.imageAlt}
								/>
							</div>
							<header className={styles.header}>
								<time itemProp="datePublished" dateTime={date.format('YYYY-MM-DD')} className={styles.date}>
									{date.format('DD.MM.YYYY')}
								</time>
								<h2 itemProp="name" className={styles.name}>
									{title}
								</h2>
							</header>
							<div itemProp="description" className={classNames([styles.description, 'text-content'])}>
								{parseHtml(shortText)}
							</div>
							<div className={styles.link}>Подробнее</div>
						</article>
					</Link>;
				})}
			</div>
			{!noPagination && (
				<div className={styles.pages}>
					{pages}
				</div>
			)}
		</div>
	);
}

export default NewsList;
